var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-main",
        { staticClass: "mt-n6", attrs: { fluid: "" } },
        [
          _c(
            "div",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "w-100",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.GetWhatToShow == "view",
                          expression: "GetWhatToShow == 'view'",
                        },
                      ],
                      staticClass: "w-100 pa-8",
                    },
                    [
                      _c("kview", {
                        attrs: { form: "siparis", viewname: _vm.GetViewName },
                        on: {
                          rowclicked: function ($event) {
                            return _vm.ViewClicked($event, "view")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.GetWhatToShow != "view",
                          expression: "GetWhatToShow != 'view'",
                        },
                      ],
                      staticClass: "pa-8",
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", tile: "", color: "primary" },
                          on: { click: _vm.BackToView },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-arrow-left"),
                          ]),
                          _vm._v(_vm._s(_vm.$t("AL_Back")) + " "),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0 mb-0" },
                            [
                              _c("dynoform", {
                                attrs: {
                                  header: "Firmamız Siparişleri",
                                  attributes: _vm.GetFormAttributes,
                                  tabname: Math.random().toString(),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.UpdateFormField(
                                      $event,
                                      "formdata"
                                    )
                                  },
                                  changeGrid: function ($event) {
                                    return _vm.UpdateExtData(
                                      $event,
                                      $event.tableName
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.FormButtonClicked($event)
                                  },
                                  SearchInputChanged: function ($event) {
                                    return _vm.SearchInputChanged(
                                      $event,
                                      "formdata"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "" },
                  model: {
                    value: _vm.actionBarWait,
                    callback: function ($$v) {
                      _vm.actionBarWait = $$v
                    },
                    expression: "actionBarWait",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "py-5",
                      attrs: { color: "transparent", dark: "" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center py-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: 50,
                              color: "white",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            {
              staticClass: "grey lighten-3 pa-3",
              attrs: { inset: "", app: "", padless: "" },
            },
            [
              _vm.actionBarWait
                ? _c("v-progress-circular", {
                    staticClass: "my-2 mr-2",
                    attrs: {
                      size: 20,
                      width: 2,
                      rounded: "",
                      indeterminate: "",
                      color: "primary",
                    },
                  })
                : _vm._e(),
              _vm.actionBarWait
                ? _c(
                    "v-toolbar-title",
                    { staticClass: "primary--text overline" },
                    [_vm._v(_vm._s(_vm.$t("IM_YourTransactionIsInProgress")))]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm._l(_vm.GetFormAction, function (actionInfo, index) {
                return _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.GetWhatToShow != "view",
                        expression: "GetWhatToShow != 'view'",
                      },
                    ],
                    key: actionInfo.action,
                    staticClass:
                      "mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize",
                    class:
                      index == _vm.GetFormAction.length - 1
                        ? "primary"
                        : "secondary",
                    attrs: { loading: _vm.actionBarWait, depressed: "" },
                    on: {
                      click: function ($event) {
                        return _vm.ApprovedAction(actionInfo)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(actionInfo.label)) + " ")]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }